<script>
import Navbar from "@/components/navbar";
import Contact from "@/components/contact";
import Footer from "@/components/footer";
import Faq from "@/components/faq";

/**
 * faq component
 */
export default {
  name: "faq",
  components: {
    Faq,
    Navbar,
    Contact,
    Footer,
  },
  data() {
    return {
      rotateindex: 0,
      rotate: [
        {
          item: 0,
          text: "Sıkça Sorulan Sorular",
          name: "faq",
          duration: 4000,
        },
      ],
    };
  },
  mounted() {
    setInterval(() => {
      this.textRotate();
    }, this.rotate[this.rotateindex].duration);
  },

  methods: {
    /**
     * Text show one after another
     */
    textRotate() {
      setTimeout(() => {
        this.rotateindex += 1;
        if (this.rotateindex >= this.rotate.length) {
          this.rotateindex = 0;
        }
      }, 0);
    },
  },
};
</script>

<template>
  <div>
    <Navbar />
    <div v-scroll-spy>
      <!--START HOME-->
      <section
        class="section section-lg height-100vh"
        id="home"
        :style="{'background-image': 'url(' + require('@/assets/images/faq/faq.svg') + ')' , 'background-size': 'cover', 'background-position': 'center'}"
      >
        <div class="bg-overlay"></div>
        <div class="display-table">
          <div class="display-table-cell">
            <div class="container">
              <div class="row">
                <div class="col-lg-8 offset-lg-2 text-white text-center">
                    <h1 class="home-title text-rotate" :key="rotateindex">
                      {{ rotate[rotateindex].text }}
                    </h1>
                    <p
                      class="padding-t-15 home-desc"
                    >Hakkımızda sormak istediğiniz bir çok soru olduğunu biliyoruz. Bu sayfada bir çok sorunuza cevap bulabileceksiniz.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--END HOME-->
      <Faq/>
      <Contact />
      <Footer />
    </div>
  </div>
</template>

<style scoped>
::v-deep .modal-content {
  background-color: #000000;
  color: white;
  background: none;
  border: none;
}
::v-deep .modal-header {
  border: none;
}
::v-deep .modal-backdrop {
  opacity: 0.5;
}
</style>